@media only screen and (max-width: 600px) {
  .benefits-details,
  .dasbhoard-description {
    font-size: 16px;
    text-align: left;
  }

  h5 {
    font-size: 22px;
    //// text-align: center !important;
  }
  #footer a {
    font-size: 12px;
  }
  #footer h5 {
    text-align: left !important;
  }

  #footer .footsection {
    padding-left: 40px !important;
  }
  .internaldesc {
    top: -34px;
  }
}

#footer {
  .resources {
    @include media-breakpoint-down(lg) {
      border: none;
    }
  }
}

@include media-breakpoint-up(sm) {
  .container {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.custom-select__indicator-separator {
  display: none;
}
.custom-select__control {
  background-color: transparent !important;
  border-width: 0 !important;
  border-bottom-width: $input-border !important;
  border-bottom-style: solid !important;
  border-color: $border !important;
  border-radius: 0 !important;
}

.custom-select__placeholder,
.custom-select__single-value {
  @media screen and (max-width: 767px) {
    font-weight: bold;
    color: $default-text !important;
  }
}
.custom-select__menu {
  background-color: inherit !important;
  text-align: left;
}
.coinsquestion {
  font-size: 14px;
}

.f1 {
  line-height: 5rem;
  font-size: 4.125rem;
  @include media-breakpoint-down(md) {
    line-height: 4rem;
    font-size: 3.125rem;
  }
}

.f2 {
  line-height: 4rem;
  font-size: 3.125rem;
}

.f3 {
  line-height: 3rem;
  font-size: 2.125rem;
}

.f4 {
  line-height: 2.5rem;
  font-size: 1.8rem;
}

.f5 {
  line-height: 1.9rem;
  font-size: 1.2rem;
}

.f6 {
  line-height: 1.2rem;
  font-size: 1rem !important;
}

.f7 {
  line-height: 1rem;
  font-size: 0.8rem !important;
}

.underlined-curve {
  position: relative;
  margin-right: 0.5rem;
}

.underlined-curve:after {
  content: '';
  position: absolute;
  bottom: -50px;
  left: -15px;
  height: 50px;
  width: 120%;
  border: solid 3px $primary;
  border-color: $primary transparent transparent transparent;
  border-radius: 60%;
}

.underlined-curve.short:after {
  content: '';
  position: absolute;
  bottom: -50px;
  left: -15px;
  height: 50px;
  width: 110%;
  border: solid 3px $primary;
  border-color: $primary transparent transparent transparent;
  border-radius: 40%;
}

.underlined-curve.info:after {
  content: '';
  position: absolute;
  bottom: -50px;
  top: 34px;
  left: -15px;
  height: 50px;
  width: 115%;
  border: solid 3px $light-purple2;
  border-color: $light-purple2 transparent transparent transparent;
  border-radius: 60%;
}

@include media-breakpoint-down(sm) {
  .underlined-curve {
    color: $primary !important;
    margin-right: 0.75rem;
  }
  .underlined-curve.info:after,
  .underlined-curve:after {
    border: none !important;
  }
}

.underlined-straight {
  position: relative;
  margin-right: 1rem;
}

.underlined-straight:after {
  content: '';
  position: absolute;
  bottom: -52px;
  left: -1px;
  height: 50px;
  width: 120%;
  border: solid 3px $primary;
  border-color: $primary transparent transparent transparent;
}

.underlined-straight.info:after {
  content: '';
  position: absolute;
  bottom: -52px;
  left: -1px;
  height: 50px;
  width: 120%;
  border: solid 3px $info;
  border-color: $info transparent transparent transparent;
}

hr {
  border-bottom: 1px solid $white;
}

.bg-secondary-light {
  background: $secondary-light !important;
}

.bg-secondary-light2 {
  background: $secondary-light2 !important;
}

.shadow-blue {
  box-shadow: 7px 7px 25px #35aaf23d !important;
}

@include media-breakpoint-down(sm) {
  .w-sm-100 {
    width: 100%;
  }
}
.w-sm-75 {
  width: 75%;
}
@include media-breakpoint-down(sm) {
  .w-sm-75 {
    width: 100%;
  }
}

.rounded-xl {
  border-radius: 1rem !important;
}

.overflow-y {
  overflow-y: auto;
}

.overflow-x {
  overflow-x: auto;
}

.opacity-50 {
  opacity: 0.5;
}

.border-2 {
  border-width: 2px !important;
}

.input-with-icon {
  width: 100%;
  position: relative;
  input {
    padding-left: 30px;
    background-position: right calc(1.9rem) center !important;
  }
  div {
    position: absolute;
    cursor: pointer;
    top: 7px;
    left: 10px;
  }
}

.hover:hover {
  opacity: 0.7;
}

.bg-default {
  background-color: #f5f6fa !important;
}

/* Header -------------------- */
.navbar-toggler {
  padding: 0;
  position: absolute;
  right: 10px;
  top: 25px;
  z-index: 1;
  border-width: 0;
}

.navbar {
  .header-logo {
    // display: none;
    max-width: 200px;
    margin-left: 1px;
    @include media-breakpoint-down(md) {
      max-width: 150px;
    }
  }
}

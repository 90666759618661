/* Extend Bootstrap Classes with New Classes -------------------- */

.nav-link-different {
	@extend .nav-link;
	color: yellow !important;
}

.form-control-noborder {
	@extend .form-control;
	border-width: 0;
	box-shadow: none;
	font-size: 16px;
	background-color: transparent;
	border-width: 0;
	border-bottom-width: $input-border;
	border-bottom-style: solid;
	border-color: $border !important;
	border-radius: 0 !important;
	@media screen and (max-width: 767px) {
		font-weight: bold;
	}
	@media screen and (max-width: 1023px) {
		font-size: 16px;
	}
	.form-control-noborder::placeholder{
		color: $default-text;
	}
}

@each $color, $value in $theme-colors {
	.hr-#{$color} {
		border-color: $value;
		border-width: 3px;
		padding: 0;
		margin: 0;
	}
}

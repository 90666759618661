.gr-1 {
  background-image: url('../../images/gradients/gradient1.png');
  background-size: cover;
  background-repeat: no-repeat;
  // background: linear-gradient(
  //   100deg,
  //   $purple 0%,
  //   $light-purple 200%
  // ) !important;
}

.gr-2 {
  background-image: url('../../images/gradients/gradient2.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gr-3 {
  background-image: url('../../images/gradients/gradient3.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gr-4 {
  background-image: url('../../images/gradients/gradient4.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gr-5 {
  background-image: url('../../images/gradients/gradient5.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gr-6 {
  background-image: url('../../images/gradients/gradient6.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gr-7 {
  background-image: url('../../images/gradients/gradient7.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gr-8 {
  background-image: url('../../images/gradients/gradient8.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gr-9 {
  background-image: url('../../images/gradients/gradient9.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gr-10 {
  background-image: url('../../images/gradients/gradient10.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gr-hero-1 {
  background-image: url('../../images/gradients/gr-hero-1.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gr-hero-2 {
  background-image: url('../../images/gradients/gr-hero-2.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.gr-hero-3 {
  background-image: url('../../images/gradients/gr-hero-3.png');
  background-size: cover;
  background-repeat: no-repeat;
}

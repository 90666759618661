.tile {
  img {
    margin-top: -20px;
  }
  hr {
    width: 100px;
    margin-top: -5px;
    border-width: 2px;
    margin-left: 0px;
  }
}

.testimonials {
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-bottom: 15rem;
  @extend .gr-2;

  .slick-slide {
    background: #9d91fb40;

    color: $white;
    padding: 1.5rem;
    margin: 2rem;

    p {
      font-family: 'Poppins Light';
      font-size: 0.8rem;
    }
  }
}

.tile-navs {
  @include media-breakpoint-down(sm) {
    overflow-x: auto;
  }
  .tile-nav {
    padding: 3rem;
    min-width: 220px;
    border-radius: 10px;
    &.border-primary {
      border-width: 2px;
      border-style: solid;
    }

    @include media-breakpoint-down(sm) {
      padding: 1rem;
      min-width: auto;
      box-shadow: none !important;
      border: 1px solid $gray;
    }
  }
}

@font-face {
  font-family: 'Open Sans Bold';
  src: local('Open Sans Bold'),
    url('../fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans Light';
  src: local('Open Sans Light'),
    url('../fonts/OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans Regular';
  src: local('Open Sans Regular'),
    url('../fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans Semibold';
  src: local('Open Sans Semibold'),
    url('../fonts/OpenSans-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome';
  font-weight: normal;
  font-style: normal;
  src: url('https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0');
  src: url('https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0')
      format('embedded-opentype'),
    url('https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0')
      format('woff2'),
    url('https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0')
      format('woff'),
    url('https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0')
      format('truetype'),
    url('https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular')
      format('svg');
}

@font-face {
  font-family: 'Poppins Extra Bold';
  src: local('Poppins Bold'),
    url('../fonts/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Bold';
  src: local('Poppins Bold'),
    url('../fonts/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins Light';
  src: local('Poppins Light'),
    url('../fonts/Poppins-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins Regular';
  src: local('Poppins Regular'),
    url('../fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins Semibold';
  src: local('Poppins Semibold'),
    url('../fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Kalam-Regular';
  src: local('Kalam-Regular'), local('Kalam-Regular'),
    url('../fonts/Kalam-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Kalam-Light';
  src: local('Kalam-Light'), local('Kalam-Light'),
    url('../fonts/Kalam-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

.toast-success {
  background: $success !important;
}

.toast-error {
  background: $danger !important;
}

.label-warning {
  background: $warning !important;
}

.label-info {
  background: $blue !important;
}
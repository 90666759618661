.blogs {
  .navbar-mask {
    content: ' ';
    position: fixed;
    top: 0px;
    left: 0px;
    height: 70px;
    right: 0px;
    // background: linear-gradient(288deg, white 0%, $purple 200%) !important;
    background: $purple;
  }

  #blogs-intro {
    @extend .hero-section;
    min-height: auto;
    text-align: center;
    .container {
      margin-top: 10rem;
    }

    .bg-grad {
      top: -1000px;
      width: 150%;
      left: -300px;
      z-index: -1;
    }
    @include media-breakpoint-down(sm) {
      .container {
        margin-top: 3rem;
      }
      .bg-grad {
        top: -300px;
      }
    }
  }

  #blogs-latest {
    @extend .sub-section;

    .blog-latest-container {
      max-width: 1100px;
      margin: auto;
    }
    .blog-latest-entry {
      margin-bottom: 40px;
    }
  }

  #blogs-latest {
    .card,
    .card-img-top {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
    .card {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }

  .blog-detail {
    margin-top: 100px;
    @extend .font-weight-light;
    @include media-breakpoint-down(sm) {
      h1 {
        font-size: 2.5rem;
      }
      h1.f2 {
        line-height: 3rem;
      }
    }
    .detail-nav {
      li {
        margin-top: 10px;
      }
      margin-left: -25px;
      li.active {
        @extend .font-weight-bold;
        a {
          @extend .font-weight-bold;
        }
      }
    }

    .detail-left-section {
      @include media-breakpoint-up(md) {
        border-right: 1px solid $light-purple;
      }
      padding-right: 0px;
    }

    .detail-right-section {
      padding-left: 4rem;
      padding-bottom: 2rem;
      @include media-breakpoint-down(md) {
        padding-left: 1rem;
      }
    }

    .find-policy {
      padding: 3rem;
      @include media-breakpoint-down(md) {
        padding: 1rem;
      }
      background: linear-gradient(
        103deg,
        $purple 50%,
        $light-purple 80%
      ) !important;
    }

    .blog-detail-reco {
      .blog-detail-reco-item {
        padding-top: 10px;
        padding-bottom: 10px;
        &.box {
          border-top: 1px solid $light-purple;
          border-bottom: 1px solid $light-purple;
        }
        @include media-breakpoint-down(md) {
          margin-right: 12px;
          &.box-bottom {
            border-bottom: 1px solid $light-purple;
          }
        }
      }
    }
  }
}

.risq-events-card {
  position: relative;
  border-radius: 1rem;
  max-width: 600px;
  & > div {
    margin-bottom: -70px;
  }
  background-color: $secondary-light;
  h1 {
    @extend .f1;
  }

  @include media-breakpoint-down(md) {
    h1 {
      font-size: 2.5rem !important;
    }
  }
}

.procurement-table-title {
  padding-left: 2px;
}

.procurement-table {
  tbody > tr > td {
    padding: 2rem;

    @include media-breakpoint-down(md) {
      padding: 0.5rem;
    }
  }
  thead > tr > th {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    @extend .text-muted;
    @include media-breakpoint-down(md) {
      padding: 0.5rem;
    }
  }

  tbody > tr:nth-child(odd) > td {
    background: #9d91fb22;
  }

  .procurment-form-link {
    color: $light-purple;
  }
}

#register-broker,
#register-partner {
  .main-section {
    border-radius: 1rem;
    @extend .gr-1;
    padding: 1.5rem;
    @include media-breakpoint-up(md) {
      padding: 4rem;
    }
  }
}

.features {
  padding-bottom: 5rem;

  .dashboard-header {
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;

    .first {
      will-change: transform, opacity, visibility;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100px) scale(0.2);
    }
    .second {
      will-change: transform, opacity, visibility;
      opacity: 0;
      visibility: hidden;
      transform: translateY(100px) scale(0.2);
    }

    // animate the header text when viewport is
    // scrolled into view
    &.show {
      .first {
        transition: 1.3s;
        opacity: 1;
        visibility: visible;
        transform: translateY(0%) scale(1);
      }
      .second {
        transition: 1.3s;
        opacity: 1;
        visibility: visible;
        transform: translateY(0%) scale(1);
      }
    }
  }

  // wrapper div for the main dashboard image and
  // absolute coin row image.
  .dashboard-img-panel {
    overflow: visible;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 7rem;
    // set height is needed for mothballed fancy animation
    // height: 70vh;
    width: 100%;
    will-change: transform, height;

    img.dashboard-proud {
      will-change: transform, opacity, visibility;
      z-index: 900;
      position: relative;
      top: 0px;
      left: 0px;
      border: 10px solid #ffffff;
      border-radius: 20px;
      box-shadow: 0px 100px 99px #00000029;
      padding: 0 !important;
    }

    @include media-breakpoint-up(sm) {
      // if we are going to animate the image, then it
      // starts hidden and down, ready to slide up to its
      // intended position
      &.will-animate {
        img.dashboard-proud {
          opacity: 0;
          visibility: hidden;
          transform: translateY(100px) scale(0.2);
        }
      }

      &.slide-up {
        img.dashboard-proud {
          transition-duration: 1.3s;
          opacity: 1;
          visibility: visible;
          // return <div> to original placement
          transform: translateY(0) scale(1);
        }
      }
    }
    /*
    &.shrink-panel {
      @include media-breakpoint-up(sm) {
        & {
          transition: height 0.1s ease-out;
          height: 20vh;
        }
      }
    }

    &.blur-shrink {
      @include media-breakpoint-up(sm) {
        img.dashboard-proud {
          filter: blur(4px); 
          transition: transform 1.3s ease-in, filter 0.5s 1.3s, opacity 1.0s ease-out 2.8s;
          opacity: 0;
          transform: translateY(-15%) scale(0.5);
        }

        & {
          transition: height 1s ease-out 2.6s;
          height: 0; // 70vh;
        }
      }
    }

    &.blur-hide {
      @include media-breakpoint-up(sm) {
        img.dashboard-proud {
          transition-duration: 1.3s;
          transition-property: opacity, visibility;
          visibility: hidden;
        }
      }
    }
    */
  }

  // The row-like image of crypto coins in a policy
  // This is for the complex animation where this image is
  // positioned over the large dashboard image.
  .coins-img {
    will-change: transform, opacity, visibility;
    position: absolute;
    border-radius: 10px;
    z-index: 1000;

    /**
      new method: no need to transform the coins image, just fade in in-place once the dashboard
      has slide-in.
    */
    &.animate {
      opacity: 0;
      visibility: hidden;
    }

    &.show {
      @include media-breakpoint-up(sm) {
        transition-delay: 1.3s; // give the main dashboard-proud image time to slide in.
        opacity: 1;
        visibility: visible;
        transition-duration: 0.2s;
        transition-property: opacity, visibility;
      }
    }

    &.slide-down {
      transition: top 1.3s ease-out;
      opacity: 1;
      visibility: visible;
      box-shadow: 0px 100px 99px #00000029;
    }
  }

  // blue panel for "Customize your crypto..."
  .coins-panel {
    z-index: -1;
    transition: border-radius 3s ease-in;
    // TODO: only for sm viewports and up
    &.animate {
      opacity: 0;
      visibility: hidden;
    }

    &.fade-in {
      // TODO: bug - if we transition opacity for the fancy animation,
      // the blue <div> overlays, the dashboard image until the transition is complete.
      transition: opacity 0.7s ease-in;
      opacity: 1;
      visibility: visible;
    }

    .coins-img-home {
      // this height is only for the complex animation
      // min-height: 20vh;

      .coins-img-inplace {
        box-shadow: 0px 100px 99px #00000029;
        // width: 900px;
      }
    }
  }
}

.track-crypto {
  padding-bottom: 5rem;

  .sub-banner {
    padding-top: 1rem;
  }
  img.track-crypto-image {
    box-shadow: 0px 100px 99px #00000029;
    border-radius: 10px;
  }

  @include media-breakpoint-down(sm) {
    .banner {
      padding-bottom: 3rem;
    }
    .sub-banner {
      padding-top: 5rem;
    }
  }
}

#home {
  background-color: $dark;
  min-height: 100vh;
  overflow: hidden;
  @include media-breakpoint-down(lg) {
    min-height: 70vh;
  }

  .bg-circle {
    position: absolute;
    max-width: 100%;
    height: auto;
    right: -470px;
    top: -500px;
  }

  .bg-grad {
    position: absolute;
    max-width: 100%;
    height: auto;
    right: -520px;
    top: -300px;
  }

  .landing-announcement {
    position: relative;
    &:hover {
      opacity: 0.8;
    }
    cursor: pointer;
    border: 1px solid $light-purple;
    display: inline-block;
    border-radius: 20px;
    // padding-left: 10px;
    // padding-right: 10px;
    // padding-top:10
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    @include media-breakpoint-down(md) {
      margin-top: -20px;
      margin-bottom: 20px;
    }
  }

  .hero-video {
    position: absolute;
    // max-width: 80%;
    height: auto;
    right: -370px;
    top: -350px;
  }

  @include media-breakpoint-down(sm) {
    min-height: auto;
    padding-top: 90px;

    .bg-circle {
      top: -260px;
      right: -260px;
    }
    .bg-grad {
      top: -230px;
      right: -200px;
    }
    .hero-video {
      right: -700px;
    }
  }

  h3 {
    line-height: 4.5rem;
    font-size: 3.5rem;
  }
  h4.subtitle {
    font-size: 22px;
    line-height: 2rem;
    margin-bottom: 20px;
  }

  p.lead {
    line-height: 1.875rem;
    font-size: 2.125rem;
  }

  @include media-breakpoint-down(sm) {
    h3 {
      line-height: 2.2rem;
      font-size: 1.875rem;
    }

    p.lead {
      line-height: 1.875rem;
      font-size: 1.125rem;
    }
  }

  .check-prices {
    border-radius: 20px;
  }
}

.landing-bg {
  z-index: -1;
  position: absolute;
  left: -2000px;
  right: 0;
  top: -100px;
  bottom: 0;
  background-image: url('../../images/misc/bg-arc2.svg');
}

.news {
  padding-top: 3rem;

  .slick-slide {
    background: white;
    margin: 10px;
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    // padding: 15px;
    // box-shadow: 1px 5px 20px 0px #485c6821 !important;
  }

  .news-content {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .news-logo {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}

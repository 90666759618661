.carousel-container {
  display: block;
  height: 500px;
  width: 100%;
  position: relative;
  padding-top: 80px;
  padding-left: 80px;

  margin-top: -100px;
  // @media screen and (max-width: 768px) {
  //   height: 50vh;
  //   padding-top: 0px;
  // }

  // @include media-breakpoint-down(md) {
  //   margin-top: -100px;
  // }

  @include media-breakpoint-down(md) {
    margin-top: -30px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: -50px;
  }
  .carousel-nav {
    button {
      background: none;
      border: none;
      &:hover {
        transform: scale(1.2);
      }
    }
    .prev,
    .next {
      top: 50px;
      opacity: 0.5;
      position: absolute;
      margin-top: 200px;
      z-index: 7;
    }
    .prev {
      margin-left: 100px;
      left: 0px;
    }
    .next {
      margin-right: 100px;
      right: 80px;
    }
    @include media-breakpoint-down(sm) {
      .prev {
        top: -30px;
        margin-left: 50px;
        left: -60px;
      }
      .next {
        top: -30px;
        right: -30px;
        // margin-right: 50px;
      }
    }
  }
}
.carousel-card-active {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.3);
  width: 25%;
  max-width: 260px;
  min-width: 250px;
  max-height: 270px;
  min-height: 250px;
  transform-origin: center;

  outline: none;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  display: block;
  z-index: 2;
  filter: none !important;
  margin-left: -40px;
  @media screen and (max-width: 768px) {
    max-width: 200px;
    min-width: 180px;
    max-height: 190px;
    min-height: 180px;
    top: 30%;
  }
}
.carousel-card-next {
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(50%, -50%) scale(1);
  width: 25%;
  max-width: 260px;
  min-width: 250px;
  max-height: 270px;
  min-height: 250px;
  transform-origin: center;
  opacity: 0.5;

  outline: none;
  position: absolute;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  display: block;
  z-index: 1;
  margin-left: -90px;
  .btn {
    border-color: #d2d2d2;
    color: #d2d2d2;
  }
  @media screen and (max-width: 768px) {
    max-width: 200px;
    min-width: 180px;
    max-height: 190px;
    min-height: 180px;
    top: 30%;
  }
}
.carousel-card-last {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(130%, -50%) scale(0.8);
  width: 25%;
  max-width: 260px;
  min-width: 250px;
  max-height: 270px;
  min-height: 250px;
  transform-origin: center;

  outline: none;
  position: absolute;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  display: block;
  z-index: 0;
  .btn {
    border-color: #d2d2d2;
    color: #d2d2d2;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.carousel-card-prev {
  position: absolute;
  left: 60%;
  top: 50%;
  transform: translate(-150%, -50%) scale(1);
  width: 25%;
  max-width: 260px;
  min-width: 250px;
  max-height: 270px;
  min-height: 250px;
  transform-origin: center;
  opacity: 0.5;

  outline: none;
  position: absolute;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  display: block;
  z-index: 1;
  .btn {
    border-color: #d2d2d2;
    color: #d2d2d2;
  }
  @media screen and (max-width: 768px) {
    max-width: 200px;
    min-width: 180px;
    max-height: 190px;
    min-height: 180px;
    top: 30%;
  }
}
.carousel-card-first {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-230%, -50%) scale(0.8);
  width: 25%;
  max-width: 260px;
  min-width: 250px;
  max-height: 270px;
  min-height: 250px;
  transform-origin: center;

  outline: none;
  position: absolute;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  display: block;
  z-index: 0;
  .btn {
    border-color: #d2d2d2;
    color: #d2d2d2;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.carousel-card-holder {
  padding: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: $white;
  box-shadow: 0px 0px 50px #35aaf23d;
  transform: scale(0.7);
  border-radius: 8px;
  .carousel-card {
    outline: none;
    width: 100%;
    height: 100%;
    -webkit-touch-cscaleout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
  }

  .carousel-amount {
    width: 100%;
    position: relative;
    input {
      background-position: right calc(1.9rem) center !important;
      padding-right: 70px !important;
    }
    div {
      position: absolute;
      cursor: pointer;
      top: 7px;
      right: 10px;
    }
    .quick-quote-text {
      color: $primary;
      position: absolute;
      line-height: 0.9;
      left: -50px;
      width: 100px;
      top: -90px;
      font-size: 2.625rem;
      font-family: 'Kalam-Light';
      transform: rotate(-20deg);
    }
    .quick-quote-arrow {
      width: 100px;
      position: absolute;
      left: -60px;
      top: 30px;
    }
  }
}

.carousel-card-hide {
  display: none;
}

.currency-header {
  padding-bottom: 1%;
  background: #d2d2d2 0% 0% no-repeat padding-box;
  // border-radius: 20px 20px 0px 0px;
  display: block;
  height: 70% !important;
  img {
    display: block;
    width: 64px;
    height: 76px;
    margin: auto;
  }
}
.currency-details {
  // border-radius: 20px 20px 0px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0px 10px 20px #0000001a;
  // border-radius: 0px 0px 20px 20px;
  opacity: 1;
  div:nth-child(1) {
    // display: none;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .main-price {
    font-size: 4rem;
    @include media-breakpoint-down(sm) {
      font-size: 3rem;
    }
  }
}

.blur-cards {
  filter: blur(5px);
}

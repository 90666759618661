/* Custom Variables -------------------- */

$disabled: #d2d2d2;
$subtext: #c6c6c6;
$border-modal: #e7e7e7;
$label-color: #707070;

$algorand: #000000;
$augur: #0e0e21;
$avalanche: #e84142;
$bat: #9e1f63;
$binance-coin: #f3ba2f;
$binance-usd: #f0b90b;
$bitcoin: #ffa601;
$bitcoin-cash: #0ac18e;
$bitcoin-sv: #eab300;
$cardano: #0033ad;
$chainlink: #2a5ada;
$dai: #f5ac37;
$dogecoin: #ba9f33;
$ethereum: #141414;
$ethereum-classic: #0b8311;
$eos: #8f8f8f;
$litecoin: #345d9d;
$terra: #172852;
$kyber: #31cb9e;
$orchid: #5f45ba;
$ox: #231815;
$polkadot: #e6007a;
$polygon: #8247e5;
$ripple: #02639a;
$shiba_inu: #f00500;
$solana: #bc3ff1;
$stellar: #000000;
$uniswap: #ff007a;
$usd_coin: #2775ca;
$tether: #50af95;
$wrapped_bitcoin: #5a5564;
$xrp: #23292f;
$zcash: #cf8724;

$algorand-gradient: #000000, #515151;
$augur-gradient: #0e0e21, #2ae7a8;
$avalanche-gradient: #e84142, #920001;
$bat-gradient: #9e1f63, #920001;
$binance-coin-gradient: #f3ba2f, #f8d581;
$binance-usd-gradient: #f0b90b, #f8d581;
$bitcoin-gradient: #ffbb01, #ff9102;
$bitcoin-cash-gradient: #0ac18e, #98e5d0;
$bitcoin-sv-gradient: #eab300, #ea9704;
$cardano-gradient: #0033ad, #00174e;
$chainlink-gradient: #2a5ada, #152d6d;
$dai-gradient: #f5ac37, #ff9100;
$dogecoin-gradient: #ba9f33, #7a6a2a;
$ethereum-gradient: #141414, #393939;
$ethereum-classic-gradient: #0b8311, #3ab83a;
$eos-gradient: #8f8f8f, #000000;
$litecoin-gradient: #345d9d, #343e9d;
$terra-gradient: #172852, #000000;
$kyber-gradient: #31cb9e, #006c4c;
$orchid-gradient: #5f45ba, #4116d8;
$ox-gradient: #231815, #3e0d00;
$polkadot-gradient: #e6007a, #96004f;
$polygon-gradient: #8247e5, #4000ac;
$ripple-gradient: #02639a, #0da1f4;
$shiba_inu-gradient: #f00500, #780300;
$solana-gradient: #bc3ff1, #00ffa3;
$stellar-gradient: #000000, #404040;
$uniswap-gradient: #ff007a, #ff00bb;
$usd_coin-gradient: #2775ca, #001b39;
$tether-gradient: #50af95, #00ffb9;
$wrapped_bitcoin-gradient: #5a5564, #f09242;
$xrp-gradient: #23292f, #3d444c;
$zcash-gradient: #cf8724, #f8ab41;

$disabled-gradient: #d2d2d2, #d2d2d2;

$gradients-landing: (
  'algorand': $algorand-gradient,
  'augur': $augur-gradient,
  'avalanche': $avalanche-gradient,
  'bat': $bat-gradient,
  'binance-coin': $binance-coin-gradient,
  'binance-usd': $binance-usd-gradient,
  'bitcoin': $bitcoin-gradient,
  'bitcoin-cash': $bitcoin-cash-gradient,
  'bitcoin-sv': $bitcoin-sv-gradient,
  'cardano': $cardano-gradient,
  'chainlink': $chainlink-gradient,
  'dai': $dai-gradient,
  'dogecoin': $dogecoin-gradient,
  'ethereum': $ethereum-gradient,
  'ethereum-classic': $ethereum-classic-gradient,
  'eos': $eos-gradient,
  'litecoin': $litecoin-gradient,
  'terra': $terra-gradient,
  'kyber': $kyber-gradient,
  'orchid': $orchid-gradient,
  'ox': $ox-gradient,
  'polkadot': $polkadot-gradient,
  'polygon': $polygon-gradient,
  'ripple': $ripple-gradient,
  'shiba_inu': $shiba_inu-gradient,
  'solana': $solana-gradient,
  'stellar': $stellar-gradient,
  'uniswap': $uniswap-gradient,
  'usd_coin': $usd_coin-gradient,
  'tether': $tether-gradient,
  'wrapped_bitcoin': $wrapped_bitcoin-gradient,
  'xrp': $xrp-gradient,
  'zcash': $zcash-gradient,
  'disabled': $disabled-gradient,
);

$theme-colors: (
  'bitcoin': $bitcoin,
  'bitcoin-cash': $bitcoin-cash,
  'ethereum': $ethereum,
  'eos': $eos,
  'ripple': $ripple,
  'disabled': $disabled,
  'subtext': $subtext,
);

// Descriptive color variables

// Functional color variables

// Font stacks

// Asset paths
// $path-image   :   '../images';
// $path-fonts     :  '../fonts';

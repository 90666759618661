#kinto {
  #intro {
    .bg-grad {
      position: absolute;
      width: 130%;
      // max-width: 100%;
      height: auto;
      right: -880px;
      top: 100px;
    }

    .breach-logo {
      width: 40px;
    }

    @include media-breakpoint-down(sm) {
      .bg-grad {
        top: -230px;
        right: -400px;
      }

      .breach-logo {
        width: 30px;
      }
    }

    @include media-breakpoint-up(md) {
      p {
        line-height: 2.5rem;
      }
    }

    @include media-breakpoint-down(sm) {
      .underlined-curve.info {
        color: $light-purple2 !important;
        margin-right: 0.75rem;
      }
    }

    .hero-image {
      right: -200px;

      @include media-breakpoint-down(md) {
        opacity: 0.3;
        top: -50px;
      }
    }
  }

  .underlined-curve:after {
    bottom: -45px;
  }

  .apply-section {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    .btn-info {
      background: $blue !important;
    }
  }

  #process-timeline {
    padding-top: 5rem;
    position: relative;
    margin-bottom: -100px;
    .circle-thin {
      position: absolute;
      top: -200px;
      right: -500px;
    }

    .circle-thin-2 {
      position: absolute;
      bottom: -200px;
      left: -900px;
    }

    p {
      margin-bottom: 150px;
    }

    background: radial-gradient(circle at center, $purple2, transparent);

    .event {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border-width: 3px;
      border-style: solid;

      &.main {
        background-color: $blue2;
        border-color: $white;
      }

      &.sub {
        background-color: $black;
        border-color: $light-purple;
        // visibility: hidden;
      }
    }

    .left-text {
      position: absolute;
      text-align: right;

      top: -10px;
      right: 50px;
      width: 350px;

      hr.bar {
        width: 75px;
        margin-right: 0px;
        margin-top: -5px;
        height: 0px;

        border: none;
        border-top: 3px solid $blue;
      }
    }

    .left-bar {
      width: 60px;

      &.active {
        border-bottom: 2px solid $white;
      }
      > div {
        position: absolute;
        top: -10px;
        left: -20px;
      }
    }

    .right-bar {
      width: 60px;
      &.active {
        border-top: 2px solid $white;
      }
      > div {
        position: absolute;
        top: -10px;
        left: -13px;
      }
    }

    .right-text {
      position: absolute;
      top: -10px;
      hr.bar {
        width: 75px;
        margin-left: 0px;
        margin-top: -5px;
        height: 0px;

        border: none;
        border-top: 3px solid $blue;
      }
    }
  }

  hr.bar-vertical {
    width: 75px;
    margin-left: 0px;
    margin-top: -5px;
    height: 0px;

    border: none;
    border-top: 3px solid $blue;
  }

  .coverage-table-container {
    background-color: rgba(109, 90, 252, 0.2);
    padding: 20px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 1rem;
    margin-bottom: 40px;
    overflow: auto;

    @include media-breakpoint-down(sm) {
      padding: 10px;
    }

    width: 100%;
    .coverage-table {
      @extend .table;
      @extend .table-borderless;

      td {
        padding: 30px;
      }

      th {
        padding-top: 60px;
      }
      @include media-breakpoint-down(sm) {
        td {
          padding: 15px;
        }

        th {
          padding-top: 10px;
        }
      }

      width: 100%;
      color: $white;

      tbody > tr > td:nth-child(2) {
        max-width: 400px;
      }
      tbody > tr > td:nth-child(1) {
        white-space: nowrap;

        @include media-breakpoint-down(sm) {
          white-space: normal;
        }
      }

      tbody > tr:nth-of-type(odd) {
        background-color: rgba(109, 90, 252, 0.2);
        td:nth-child(1) {
          border-top-left-radius: 1rem;
          border-bottom-left-radius: 1rem;
        }
        td:nth-child(2) {
          border-top-right-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }
      }
    }
  }

  .coverage-details {
    background: rgba(109, 90, 252, 0.2);
  }

  .coverage {
    .coverage-item {
      margin-bottom: 3rem;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }
    }

    .termination {
      border-left: 2px solid $purple;
      @extend .pl-5;
      @include media-breakpoint-down(md) {
        padding-left: 1rem !important;
        border-left: none;
      }
    }
  }
}

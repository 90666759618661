html {
  scroll-behavior: smooth;
}

.carousel {
  margin: 0 auto;
  width: 800px;
  height: 300px;
  display: relative;
}
.carousel img {
  display: hidden; /* hide images until carousel prepares them */
  cursor: pointer; /* not needed if you wrap carousel items in links */
}

#footer {
  position: relative;
  margin-top: 100px;
  padding-top: 50px;
  padding-bottom: 75px;
  color: rgba(0, 0, 0, 0.6);
  // &.small {
  //   padding-top: 20px;
  // }
  h5 {
    font-size: 16px;
  }

  .resources {
    border-left: 1px solid #00405914;
    border-right: 1px solid #00405914;
  }

  .copy-text {
    border-left: 1px solid white;
    @include media-breakpoint-down(md) {
      border-left: none;
    }
  }
}

b {
  font-weight: 100;
  font-family: 'Poppins Bold';
}

#footer a {
  font-family: 'Poppins Regular';
  font-size: 12px;
}

#footer b {
  border-bottom: 3px solid $body-bg;
  padding-bottom: 10px;
}

.nav a {
  font-family: 'Poppins Regular';
  text-align: left;
  font-size: 14px;
  letter-spacing: 0;
}
.btn {
  padding: 4px 20px;
}

.navbar {
  // border-bottom: 2px solid $border-color;
  position: fixed;
  background: linear-gradient(180deg, $white, $gray);
  color: $nav-text;
  padding-top: 15px;
  padding-bottom: 15px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
  transition: 0.5s;

  // &.open {
  //   background: $purple;
  // }

  .nav-btn {
    // display: none;
    border: $blue;
    border-width: 2px;
    border-style: solid;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    background: transparent;
    color: $blue !important;
    margin-left: 15px;
    margin-right: 15px;
    white-space: nowrap;
    &:hover {
      color: $nav-text !important;
      background: $blue;
    }
  }

  &.down {
    box-shadow: 0px 3px 25px #35aaf23d;
    // background: linear-gradient(180deg, $purple, #050126);

    .container {
      transition: 0.5s;
      transform: scale(0.95);
    }
  }

  .nav-link {
    color: $purple;
  }

  .nav-item.active {
    .nav-link {
      text-decoration: underline;
      text-underline-offset: 7px;
      text-decoration-thickness: 2px;
      font-family: 'Poppins Bold';
      text-decoration-color: $blue;
    }

    .sub-menu {
      .nav-link {
        font-family: 'Poppins Regular';
      }
    }
  }

  .sub-menu-container {
    .sub-menu {
      display: none;
      position: absolute;
      // padding-top: 10px;
      // background: lighten($purple, 5%);
      background: linear-gradient(180deg, $white, $gray);
      border-radius: 10px;
      .nav-link {
        text-decoration: none;
      }
    }

    .sub-menu-drawer {
      height: 0;
      overflow: hidden;
    }

    &:hover {
      // background-color: $purple;

      .sub-menu {
        display: block;
      }
      .sub-menu-drawer {
        height: 100%;
      }
    }
  }

  .header-menu {
    flex-wrap: nowrap;
    li {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.nav-link {
  white-space: nowrap;
  &:hover {
    color: $navbar-dark-color !important;
    opacity: 0.75;
  }
}

h1 {
  font-family: 'Poppins Light';
}
.subtitle {
  font-family: 'Poppins Light';
  font-size: 18px;
}

.dasbhoard-description {
  font-family: 'Poppins Light';
  font-size: 18px;
}

.internaldesc {
  position: relative;
  top: -70px;
}

.currencyInput {
  width: 63px;
  font-size: 23px;
  padding: 0;
  margin: 0;
  border: 0;
  box-shadow: none;
  outline: 0;
  text-align: center;
}
.topradius {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.bottomradius {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.greyheader {
  color: #707070;
}

.darkheader {
  color: #525252;
}

.selectWrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  border: 0;
  background: #ffffff
    url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211%22%20height%3D%2211%22%20viewBox%3D%220%200%2011%2011%22%3E%3Cpath%20d%3D%22M4.33%208.5L0%201L8.66%201z%22%20fill%3D%22%2300AEA9%22%2F%3E%3C%2Fsvg%3E')
    right 13px center no-repeat;
}

.selectWrapper select {
  font-size: 14px;

  width: 100%;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: ellipsis;
}

.text-sm {
  font-size: 11px;
}

.text-label {
  color: $label-text;
}

.text-bold {
  font-weight: bolder;
  font-family: 'Poppins Bold';
}

.text-semi-bold {
  font-weight: bold;
  font-family: 'Poppins SemiBold';
}

.text-black {
  color: $black;
}

.text-inherit {
  color: inherit;
}

.text-cursive {
  font-family: 'Alex Brush';
}

.mouse-pointer {
  cursor: pointer;
}

.overflow {
  overflow: auto;
}

.rounded-xl {
  border-radius: 0.5rem;
}

.header-menu {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

/* Base Styles -------------------- */
@each $color, $value in $gradients-landing {
	.gradient-#{$color} {
		@include gradient-y(nth($value,1), nth($value,2))
	}
}


.subtext {
	color: darken($subtext,20);
	font-size: 16px;
}


.col-form-label{
	@extend .col-form-label;
	@media screen and (max-width: 768px) {
		color: $subtext;
	}
}
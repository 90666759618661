.statistics {
  padding-top: 3rem;
  padding-bottom: 3rem;

  .statistic-header {
    margin-top: 35px;
    letter-spacing: 0;
    color: $light;
  }

  .statistic-details {
    width: 300px;
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: $gray;
  }

  .banner {
    color: $light;
  }

  .icon-panel {
    width: 80px;
    height: 64px;
    background-color: white;
    color: $primary;
    font-size: 20px;
    border-radius: 10px;
    text-align: center;

    i {
      vertical-align: middle;
      line-height: 1.5;

      &.fa-stack-1x {
        line-height: 3;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .statistics {
    padding-top: 5rem;
  }
}

@include media-breakpoint-down(xs) {
  .statistics {
    padding-top: 5rem;
  }
}
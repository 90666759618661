.contact-us {
  margin-top: 5rem;
  p {
    font-size: 14px;
  }
  .contact-us-panel {
    // background: linear-gradient(270deg, #050126 0%, #0d04539c 100%) 0% 0%
    //   no-repeat padding-box;
    background-image: url('../../images/contactus/bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 10px 10px 50px #35aaf340;
    @include media-breakpoint-down(sm) {
      box-shadow: none;
    }
    .form-control {
      background: inherit;
      color: $white;
    }

    border-radius: 20px;
    opacity: 1;
    z-index: 5;
    // margin-bottom: -180px;

    .form-control-noborder {
      font-size: 1.25rem;
      color: #707070;
      border: 0;
      border-bottom: 1px solid $gray;
      border-radius: 0;
      text-align: center;
      padding: 12px;
    }

    textarea {
      font-size: 1.25rem;
      color: $white;
      background: inherit;
      // border: 0;
      // border-bottom: 1px solid $gray;
      // border-radius: 0;
      padding: 12px;
      resize: none;
      width: 100%;
      border-color: #ffffff80;
    }

    textarea:focus {
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 0.2rem rgba(53, 170, 242, 0.25);
    }

    textarea::placeholder {
      transform: translateY(100%);
      text-align: center;
    }

    select,
    input {
      border: none;
      border-radius: 0px;
      border-bottom: 1px solid #ffffff80;
    }

    .has-error .form-control {
      border: none !important;
      border-bottom: 1px solid $danger !important;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  }

  .captcha-row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  .input-with-icon {
    width: 100%;
    position: relative;
    input {
      background-position: right calc(1.9rem) center !important;
    }
    div {
      position: absolute;
      cursor: pointer;
      top: 7px;
      left: 10px;
    }
  }

  .form-group {
    margin-bottom: 50px;
  }
  .priv-check {
    padding-left: 19px;
  }
}

$default-text: #707070;
$label-text: #525252;
$gray: #c6c6c6;
$purple: #050126;
$purple2: #0b1e6d;
$purple3: #0d0453;
$purple4: #020f44;
$light-purple: #6d5afc;
$light-purple2: #9d91fb;

$dark: $purple;
$dark2: black;
$light: rgba(0, 0, 0, 0.49);
$black: #050126;
$white: white;
$gray2: #ccc;
$label: #707070;
$border: #f1f1f1;
$default-underline: #e7e7e7;

$nav-text: $purple;

// $lightwhite: #dc354;
$blue: #00a6f8;
$blue2: #5ebbf5;
$info: $light-purple;
$primarygreen: #73ea97;
$green: #52e57e;
$green2: #12bbad;
$darkgreen: #28a745;
$yellow: #ffc107;
$darker-white: #f4fbfd;
$secondary: tint($blue, 68%);
$secondary-light: #037dce0f;
$secondary-light2: #037dce20;
$danger: #ff7b7b;
$danger-bg: tint($danger, 20%);
$success: #2dce98;
$success-bg: tint($success, 20%);
$warning: #dfb81c;
$warning-bg: tint($warning, 20%);

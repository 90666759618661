/* Bootstrap Overrides -------------------- */

$navbar-dark-color: $nav-text;
// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded: true !default;
$enable-shadows: true;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

// Variables
$input-border: 1px;
//
// Colors
$modal-background: #ffffff;

$theme-colors: (
  primary: $purple,
  secondary: $light-purple,
  light: #ffffff80,
  white: $white,
  dark: $dark,
  info: $blue,
  success: $darkgreen,
  warning: $yellow,
  danger: $danger,
  label: $label,
  border: $border,
  whitedarker: $darker-white,
);

$container-max-widths: (
  md: 820px,
  lg: 960px,
  xl: 1400px,
);

$body-bg: $white;
$body-color: $dark2;

$body-color-inverse: invert($body-color) !default;
$link-color: $green2;
$border-color: $blue2;
$modal-content-box-shadow-xs: 0px 0px 40px #0000000d !important;
$modal-content-border-radius: 10px;

// Fonts
$font-family-base: 'Poppins Regular', 'Open Sans Regular', -apple-system,
  BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$headings-font-family: $font-family-base;
$display-font-family: $font-family-base;
$font-weight-normal: 200;
$headings-font-weight: 200;
$lead-font-size: 1.3rem;

$spacer: 1.5rem;

.font-weight-light {
  font-family: 'Poppins Light';
}

.font-weight-bold {
  font-family: 'Poppins Bold';
}

.font-weight-extra-bold {
  font-family: 'Poppins Extra Bold';
}

.font-weight-semi-bold {
  font-family: 'Poppins SemiBold';
}

.modal-open .modal {
  display: flex !important;
  align-items: center !important;
  .modal-dialog {
    flex-grow: 1;
  }
}

.modal-backdrop {
  background-color: #ffffff !important;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
.modal-open .container-fluid,
.modal-open .container {
  -webkit-filter: blur(5px) grayscale(0%);
}
body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}
.modal-content {
  background-color: $modal-background !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.btn {
  padding: 8px 20px !important;
}

.btn-primary {
  border: 0 !important;
  background: linear-gradient(288deg, $purple3 20%, $blue 100%) !important;
  // background: linear-gradient(
  //   270deg,
  //   $light-purple 30%,
  //   $purple4 70%
  // ) !important;
}

.btn-info {
  color: $white;
  border: 0 !important;
  // background: linear-gradient(
  //   103deg,
  //   $purple2 30%,
  //   $light-purple 80%
  // ) !important;
  background: linear-gradient(
    270deg,
    $light-purple 30%,
    $purple4 70%
  ) !important;
}

// .btn-info.reverse {
//   background: linear-gradient(
//     207deg,
//     $purple2 30%,
//     $light-purple 80%
//   ) !important;
// }

.btn:hover {
  scale: 1.05;
}

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1250px,
);

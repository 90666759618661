.event-container {
  max-width: 1200px;
  padding-left: 5rem;
  padding-right: 5rem;
  margin: auto;

  @include media-breakpoint-down(sm) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .event-line {
    border-bottom: 3px solid $white;
  }
  .events {
    margin-top: -120px;
    .stem {
      height: 90px;

      margin-left: 9px;
      &.enabled {
        border-left: 3px solid $white;
      }
    }

    .event {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border-width: 3px;
      border-style: solid;

      &.main {
        background-color: $blue2;
        border-color: $white;
      }

      &.sub {
        background-color: $black;
        border-color: $light-purple;
        visibility: hidden;
        &.enabled {
          visibility: visible;
        }
        &.up > div {
          min-width: 200px;
          margin-top: -120px;
          text-align: center;
          margin-left: -90px;
        }

        &.down > div {
          min-width: 200px;
          margin-top: 30px;
          text-align: center;
          margin-left: -90px;
        }
      }
    }
  }

  &.vertical {
    .events {
      .event {
        margin-left: -35px;
        margin-top: -2px;
        position: absolute;
        z-index: 1;
      }
    }

    h3 {
      margin-top: -5px;
    }
  }
}

#services {
  #services-intro {
    @extend .hero-section;
  }

  #services-other {
    @extend .sub-section;

    .blue-box {
      position: absolute;
      background: $blue2;
      border-radius: 10px;
      opacity: 0.1;
      z-index: -1;
      left: -50px;
      right: 30%;
      top: 0px;
      bottom: -50px;
    }
  }
}

@mixin validationState($color, $focusColor) {
  .control-label {
    color: $default-text;
  }
  .form-control {
    border: 1px solid $color !important;
    &:focus {
      box-shadow: none;
      border-color: $focusColor;
    }
  }

  label.custom-checkbox {
    color: $color;
    & > span {
      &:before {
        color: $color;
      }
      &:hover {
        &:before {
          border-color: $color;
        }
      }
    }
  }

  .form-control-feedback {
    color: $color;
  }

  .input-group-addon {
    background-color: $color;
    color: $label-text;
  }
}

.has-success {
  @include validationState($success-bg, $success);
}

.has-warning {
  @include validationState($warning-bg, $warning);
}

.has-error {
  @include validationState($danger-bg, $danger);
  .help-block {
    color: $danger-bg;
  }
}

.sub-little-text {
  font-size: 0.75rem;
}

.close-button {
  position: absolute;
  top:0px;
  right: 10px;
}
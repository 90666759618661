.partners {
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-bottom: 2rem;
  background: radial-gradient(circle at center, $purple2, transparent);
  @extend .gr-3;

  p {
    font-family: 'Poppins Light';
  }
}

#partners-page {
  #partners-page-intro {
    @extend .hero-section;
  }

  .h-mask {
    height: 100px;
    @include media-breakpoint-down(sm) {
      height: 60px;
    }
  }
}

/* Containers -------------------- */

.mobile-modal {
	transition: 0.3s;
	opacity: 1;
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	overflow-y: scroll;
	overflow-x: hidden;
}

.mobile-modal-hidden {
	opacity: 0;
	z-index: -10;
	transition: 0.3s;
}

.mobile-modal-header {
	transition: 0.5s;
	opacity: 1;
	padding-bottom: 20px !important;
	padding-top: 40px !important;
	width: 100%;
	background-color: $primary;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	.mobile-header-title {
		vertical-align: middle !important;
	}
}
.mobile-modal-header-hidden {
	transition: 0.5s;
	opacity: 0;
	height: 100%;
}

.card-transition-container {
  position: relative;
  height: 70vh;
  width: 100%;
}
.card-transition-img-up {
  opacity: 0;
  visibility: hidden;
  transform: translateY(100px) scale(0.2);
}

.card-transition-text-up {
  opacity: 0;
  visibility: hidden;
  transform: translateY(100px) scale(0.2);
}

.card-transition-text-left {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-200px);
}

.card-transition-text-right {
  opacity: 0;
  visibility: hidden;
  transform: translateX(200px);
}

.card-transition-img-down {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100px) scale(0.2);
}

.card-transition-img-left {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-200px);
}

.card-transition-img-right {
  opacity: 0;
  visibility: hidden;
  transform: translateX(200px);
}

.card-transition-text-down {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100px) scale(0.2);
}

.card-transition-text-visible {
  transition: 1.3s;
  opacity: 1;
  visibility: visible;
  transform: translateY(0%) scale(1);
}

.card-transition-img-visible {
  transition: 1.3s;
  opacity: 1;
  visibility: visible;
  transform: translateY(-20%) scale(1);
}

.card-transition-img-visible-original {
  transition: 1.3s;
  opacity: 1;
  visibility: visible;
  transform: translateY(0%) scale(1);
}

@include media-breakpoint-down(sm) {
  .card-video {
    max-height: 200px;
  }
}

.card-transition-img-blur-small {
  transition: 1.3s;
  opacity: 1;
  visibility: visible;
  transform: translateY(-25%) scale(0.6);
  filter: blur(4px);
}

.card-transition-img-blur-small-hidden {
  transition: 1.3s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-25%) scale(0.6);
  filter: blur(4px);
}

.card-transition-img-secondary {
  position: absolute;
  transition: 1.3s;
  top: 0;
  left: 0;
  opacity: 1;
  visibility: visible;
  transform: scale(0.5);
  transform: translateY(5%) translateX(62%) scale(0.5);
  @media (min-width: 768px) {
    transform: translateY(5%) translateX(62%) scale(0.5);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    transform: translateY(-20%) translateX(10%) scale(0.3);
  }
  @media (min-width: 1100px) and (max-width: 1439px) {
    transform: translateY(0%) translateX(55%) scale(0.5);
  }
  @media (min-width: 2560px) {
    transform: translateY(40%) translateX(138%) scale(0.8);
  }
}

.card-transition-img-secondary-hidden {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.38);
}

.card-transition-img-secondary-left {
  position: absolute;
  top: 0;
  left: 0;
  transition: 1.3s;
  opacity: 1;
  visibility: visible;
  transform: translateY(50%) translateX(20%) scale(0.8);
  @media (min-width: 768px) {
    transform: translateY(50%) translateX(20%) scale(0.8);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    transform: translateY(40%) translateX(0%) scale(0.6);
  }
  @media (min-width: 1100px) and (max-width: 1440px) {
    transform: translateY(50%) translateX(10%) scale(0.8);
  }
  @media (min-width: 2560px) {
    transform: translateY(90%) translateX(80%) scale(1);
  }
}

.card-transition-details {
  transition: 1.3s;
  position: absolute;
  top: 30%;
  z-index: -1;
  opacity: 1;
  visibility: visible;
  @media (min-width: 768px) {
    top: 30%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    top: 12%;
  }
  @media (min-width: 1100px) and (max-width: 1440px) {
    top: 22%;
  }
  @media (min-width: 2560px) {
    top: 40%;
  }
}
.card-transition-details-hidden {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 100%;
}

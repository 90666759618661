#embedded-api {
  #intro {
    .underlined-curve:after {
      bottom: -50px;
      left: -15px;
      height: 50px;
      width: 140%;
      border: solid 3px $info;
      border-color: $info transparent transparent transparent;
      border-radius: 52%;
    }
  }

  .underlined-curve {
    margin-right: 0px;
  }

  #benefits {
    .underlined-curve:after {
      border-radius: 52%;
      bottom: -40px;
      left: -20px;
      width: 100px;
    }
  }
  #feature {
    .underlined-curve:after {
      border-radius: 52%;
      bottom: -40px;
      left: -10px;
    }
  }
}

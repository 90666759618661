.about-us {
  #about-intro {
    @extend .hero-section;
  }

  #about-origin {
    position: relative;
    margin-top: 10rem;
    margin-bottom: 10rem;

    &::before {
      content: ' ';
      // height: 500px;
      background-color: $blue2;
      opacity: 0.1;
      display: block;
      position: absolute;
      left: 0px;
      right: 70%;
      bottom: -50px;
      top: -50px;
    }

    &::after {
      content: ' ';
      // height: 500px;
      // background-image: url('../../images/misc/circle-gradient.png');
      opacity: 0.1;
      display: block;
      position: absolute;
      left: 70%;
      right: 0;
      bottom: -50px;
      top: -50px;
    }
  }

  h3 {
    line-height: 4.5rem;
    font-size: 3.5rem;
  }
  h4.subtitle {
    font-size: 22px;
    line-height: 2rem;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    h3 {
      line-height: 2.2rem;
      font-size: 1.875rem;
    }

    p.lead {
      line-height: 1.875rem;
      font-size: 1.125rem;
    }
  }

  #about-timeline {
    padding-top: 5rem;

    p {
      margin-bottom: 150px;
    }
    @include media-breakpoint-up(lg) {
      p {
        margin-bottom: 300px;
      }
    }

    background: radial-gradient(circle at center, $purple2, transparent);
    @include media-breakpoint-up(lg) {
      padding-bottom: 15rem;
    }
  }

  .team {
    .team-member {
      position: relative;
      max-width: 280px;
      max-height: 400px;

      .name-tag-holder {
        position: absolute;
        padding: 0.4rem 0.7rem;
        left: 10px;
        right: 10px;
        bottom: -12px;
        background: #0d04533c;
        border-radius: 12px;
      }
    }
  }
}

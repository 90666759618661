.quote-input-panel {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .quote-item {
    font-size: 1.5rem;
    flex: none;
    border-bottom-width: 2px !important;
    border-bottom-style: solid !important;
    border-bottom-color: $blue;
		margin: 0;
  }

  input.quote-item {
    color: $primary;
    border-width: 0px;
    width: 8rem;
    box-shadow: none;
    // line-height: 1.2;
  }

  @media screen and (max-width: 576px) {
    .quote-item {
      font-size: 1.2rem;
    }

    input.quote-item {
      width: 6rem;
    }
  }
}

.foot-note {
  @include media-breakpoint-down(sm) {
    margin-bottom: 4rem;
  }
}
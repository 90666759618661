/* Layout -------------------- */

.hero-section {
  background-color: $dark;
  min-height: 100vh;
  overflow: hidden;
  padding-bottom: 4rem;

  .container {
    margin-top: 16rem;
    position: relative;
  }

  @include media-breakpoint-down(md) {
    min-height: 70vh;
    .container {
      margin-top: 10rem;
    }
  }

  @media screen and (min-height: 1180px) {
    min-height: 60vh;
  }

  .kinto-logo {
    position: relative;
    z-index: 20;
    top: -50px;
    left: 10px;
    width: 300px;
    @include media-breakpoint-down(lg) {
      position: relative;
      width: 150px;
      left: 10px;
      top: -10px;
    }
  }
  .rizzo-logo {
    position: relative;
    z-index: 20;
    left: 20px;
    top:-65px;
    width: 300px;
    @include media-breakpoint-down(lg) {
      position: relative;
      width: 200px;
      left: 20px;
      top: -65px;
    }
    @include media-breakpoint-down(sm) {
      position: relative;
      width: 150px;
      left: 20px;
      top: -20px;
      margin-bottom: 10px;
    }
  }
  .unchained-logo {
    position: relative;
    z-index: 20;
    top: -60px;
    left: 15px;
    width: 400px;
    @include media-breakpoint-down(lg) {
      position: relative;
      width: 250px;
      left: 20px;
      top: -20px;
    }
  }

  h3 {
    line-height: 4.5rem;
    font-size: 3.5rem;
  }
  h4.subtitle {
    font-size: 22px;
    line-height: 2rem;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    min-height: auto;
    padding-bottom: 0rem;
    padding-top: 55px;
    .container {
      margin-top: 3rem;
    }
    h3 {
      line-height: 2.2rem;
      font-size: 1.875rem;
    }

    p.lead {
      line-height: 1.875rem;
      font-size: 1.125rem;
    }
  }

  .hero-video {
    position: absolute;

    @include media-breakpoint-down(md) {
      opacity: 0.4;
    }

    height: auto;
    right: -320px;
    top: -350px;

    &.right-1 {
      right: -300px;
    }
    &.right-2 {
      right: -460px;
    }

    &.right-3 {
      right: -750px;
    }
    &.right-4 {
      right: -480px;
    }
  }

  @include media-breakpoint-down(sm) {
    .hero-video {
      right: -550px;
      &.right-1 {
        right: -550px;
      }
      &.right-2 {
        right: -750px;
      }
      &.static {
        right: -220px;
      }

      &.right-3 {
        right: -1200px;
      }
      &.right-4 {
        right: -900px;
      }
    }
  }

  .hero-image {
    position: absolute;

    height: auto;
    right: -280px;
    top: -150px;

    @include media-breakpoint-down(md) {
      opacity: 0.4;
    }
  }
}

.sub-section {
  margin-top: 5rem;
  margin-bottom: 5rem;
  @include media-breakpoint-down(sm) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  p {
    @extend .f6;
    line-height: 1.5rem !important;
    max-width: 800px;
    margin: auto;
  }
}

.key-benefits {
  min-height: 80vh;

  .benefit-header {
    margin-top: 35px;
    letter-spacing: 0;
    color: $dark;
  }

  .benefit-details {
    width: 300px;
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: darken($gray, 20);
  }

  @include media-breakpoint-down(sm) {
    margin-top: 100px;
  }
}

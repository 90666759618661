.modal-header {
  border: 0;
}

.modal-title{
  font-family: 'Open Sans Bold';
  letter-spacing: 0;
  font-size: 18px;
  line-height: 23px;
  color: #525252;
  opacity: 1;
}

.modal-footer {
  border: 0;
}

.modal-content {
  text-align: left;
  letter-spacing: 0;
  font-family: 'Open Sans Light';
  font-size: 14px;
  color: $label-color;
  border-color: $border-modal; 
  line-height: 25px;
  padding-top: 1.8rem;

  .horizontal-rule {
    width: 100%;
    padding-left: $modal-header-padding-x;
    padding-right: $modal-header-padding-x;
  }
}

.modal-backdrop {
  background-color: #ffffff !important;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
.modal-open {
  .wrapper {
    -webkit-filter: blur(20px) grayscale(0%);
    filter: blur(20px) grayscale(0%);
  }
}
/*
.modal-header .close::after {
  content: '\f057';
  font-weight: 900;
  color: #ff7b7b;
  font-family: 'FontAwesome';
  margin: 0 !important;
  padding-right: 5px !important;
}
*/
@include media-breakpoint-up(md) {
  .modal-header .close {
    background-image: url('../../images/utils/close.svg');
    border: 0px;
    margin-left: auto;
    margin-right: 0px; // -1rem;
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    width: 1.125rem;
    height: 1.125rem;
  }
}

.close span {
  display: none;
}

@include media-breakpoint-down(sm) {
  .modal-dialog {
    position: fixed;
    margin: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  // IAIN: check for attributes to remove below e.g. flex etc.
  .modal-header {
    transition: 0.5s;
    opacity: 1;
    padding-bottom: 20px !important;
    padding-top: 40px !important;
    width: 100%;
    height: 7.4rem;
    background-color: $blue;
    border: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .modal-title {
      color: white;
      vertical-align: middle !important;
    }

    .close {
      position: absolute;
      top: 1.875rem;
      right: 2rem;
      cursor:pointer;
      margin: 0px;

      &:after, &:before {
        
        content:"";
        height:20px;
        width:20px;
        border-top:1px solid white;
        position:absolute;
        top:7px;
        right:-8px;
        // @include rotate(-45deg);
        transform: rotate(-45deg)
      
      }
          
      &:before {
        
        right:6px;
        // @include rotate(45deg);
        transform: rotate(45deg)
      
      }
      
      &:hover {
        opacity: 0.3;
      }
    }
    /*
    .close::after {
      content: '\f057';
      font-weight: 900;
      color: #ff7b7b;
      font-family: 'FontAwesome';
      // margin: 0 !important;
      // padding-right: 5px !important;
    }
    */
  }

  #menu-modal.modal-header {
    height: auto;
  }

  .modal-content {
    padding: 0px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    box-shadow: none;

    .horizontal-rule {
      display: none;
    }
  }
}
#wallet-assessment {
  #intro {
    .bg-grad {
      position: absolute;
      width: 130%;
      // max-width: 100%;
      height: auto;
      left: -920px;
      top: -650px;
    }

    .underlined-curve.info:after {
      bottom: -50px;
      left: -15px;
      height: 50px;
      width: 140%;
      border: solid 3px $info;
      border-color: $info transparent transparent transparent;
      border-radius: 52%;
    }

    @include media-breakpoint-down(sm) {
      .underlined-curve.info {
        color: $primary !important;
        margin-right: 0.3rem;
      }
    }
  }

  #wallet-assessment-benefits {
    .underlined-curve:after {
      border-radius: 52%;
      bottom: -40px;
    }
    .underlined-curve {
      margin-right: 0rem;
    }
  }

  #start-assessment {
    input[type='email'] {
      background-color: $purple;
      padding: 25px;
      color: $white;
      border-radius: 0.5rem;
    }
    button {
      margin-left: -10px;
      background: $blue !important;
      border-radius: 0.5rem;
      @include media-breakpoint-down(sm) {
        margin-left: 0px;
      }
    }
  }

  #report {
    a {
      // margin-left: 10px;
      height: 40px;
      // padding-left: 150px !important;
      // padding-right: 150px !important;
    }
  }

  #simple-steps {
    .underlined-curve {
      margin-right: 0rem;
    }

    button {
      background: $blue !important;
    }
    .underlined-curve:after {
      border-radius: 52%;
      bottom: -40px;
    }
  }
}

.product-summary {
  margin-bottom: 10rem;
  @include media-breakpoint-down(sm) {
    margin-bottom: 0rem;
  }
  .shadow {
    box-shadow: 0px 0px 99px #35aaf221 !important;
  }

  .product-accordion-item {
    padding: 3rem;
    padding-bottom: 2rem;
    .description {
      color: #004059aa !important;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    // color:
  }

  .details {
    min-height: 620px;
  }
}

.product-table-holder {
  @extend .gr-5;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  @include media-breakpoint-down(md) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  color: $white;
  border-radius: 1rem;

  @include media-breakpoint-down(sm) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  table {
    width: 100%;
    margin-bottom: 1.5rem;
    border: none;
    tr {
      vertical-align: middle;
    }
    th,
    td {
      padding: 0.75rem;
      padding-left: 40px;
      @include media-breakpoint-down(md) {
        padding-left: 10px;
      }
    }

    td:nth-child(2),
    td:nth-child(3) {
      text-align: center;
    }

    tr:nth-child(even) {
      background-color: #9d91fb33;
      td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }
}

.promo-bar {
  position: fixed;
  width: 100%;
  color: $white;
  background-color: $primary;
  text-align: center;
  padding: 10px;
  z-index: 30;
  @include media-breakpoint-down(sm) {
    padding: 13px;
    font-size: 12px;
  }
}

.promo {
  .navbar {
    top: 40px;
  }

  #faq,
  #blogs {
    @include media-breakpoint-down(sm) {
      min-height: auto;
      margin-top: 35px;
    }
  }

  #home,
  #about-us {
    // margin-top: 80px;
    @include media-breakpoint-down(sm) {
      min-height: auto;
      // margin-top: 80px;
    }
  }
  #crypto-shield,
  #crypto-shield-pro,
  #about-us {
    // margin-top: 80px;
    @include media-breakpoint-down(sm) {
      padding-top: 40px;
      // margin-top: 80px;
    }
  }
}

#cyber-insurance {
  #intro {
    .bg-grad {
      position: absolute;
      width: 130%;
      // max-width: 100%;
      height: auto;
      left: -920px;
      top: -650px;
    }

    @include media-breakpoint-up(md) {
      p {
        line-height: 2.5rem;
      }
    }

    .underlined-curve.info:after {
      top: 27px;
      // bottom: -50px;
      // left: -15px;
      // height: 50px;
      // width: 140%;
      // border: solid 3px $info;
      // border-color: $info transparent transparent transparent;
      // border-radius: 52%;
    }

    @include media-breakpoint-down(sm) {
      .underlined-curve.info {
        color: $primary !important;
        margin-right: 0.75rem;
      }
    }
  }

  .underlined-curve:after {
    bottom: -45px;
  }

  #cyber-insurance-policies {
    .highlights {
      .row > div:nth-child(1) {
        @extend .font-weight-semi-bold;
        border-right: 0.5px solid $light-purple;
        @include media-breakpoint-down(sm) {
          border-right: none;
        }
      }

      .row > div:nth-child(2) {
        min-height: 120px;
        @include media-breakpoint-down(sm) {
          min-height: auto;
        }
      }

      .row.last > div:nth-child(2) {
        min-height: 10px;
        @include media-breakpoint-down(sm) {
          min-height: auto;
        }
      }

      .title {
        hr {
          display: none;
        }
        @include media-breakpoint-down(sm) {
          hr {
            display: block;
            margin: 0px;
            width: 100px;
            border: 1px solid $light-purple;
          }
          // width: 300px;
        }
      }
    }
  }

  .apply-section {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    .btn-info {
      background: $blue !important;
    }
  }

  #process-timeline {
    padding-top: 5rem;
    position: relative;

    .circle-thin {
      position: absolute;
      top: -600px;
      right: -500px;
    }

    p {
      margin-bottom: 150px;
    }

    background: radial-gradient(circle at center, $purple2, transparent);

    .event {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border-width: 3px;
      border-style: solid;

      &.main {
        background-color: $blue2;
        border-color: $white;
      }

      &.sub {
        background-color: $black;
        border-color: $light-purple;
        // visibility: hidden;
      }
    }

    .left-text {
      position: absolute;
      text-align: right;

      top: -10px;
      right: 50px;
      width: 350px;

      hr.bar {
        width: 75px;
        margin-right: 0px;
        margin-top: -5px;
        height: 0px;

        border: none;
        border-top: 3px solid $blue;
      }
    }

    .left-bar {
      width: 60px;

      &.active {
        border-bottom: 2px solid $white;
      }
      > div {
        position: absolute;
        top: -10px;
        left: -20px;
      }
    }

    .right-bar {
      width: 60px;
      &.active {
        border-top: 2px solid $white;
      }
      > div {
        position: absolute;
        top: -10px;
        left: -13px;
      }
    }

    .right-text {
      position: absolute;
      top: -10px;
      hr.bar {
        width: 75px;
        margin-left: 0px;
        margin-top: -5px;
        height: 0px;

        border: none;
        border-top: 3px solid $blue;
      }
    }
  }

  hr.bar-vertical {
    width: 75px;
    margin-left: 0px;
    margin-top: -5px;
    height: 0px;

    border: none;
    border-top: 3px solid $blue;
  }
}

#cyber-insurance-stats {
  .underlined-curve:after {
    width: 110%;
  }
}
